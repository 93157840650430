/**
 * General Link Validation
 */

//192.168.
export const linkValidation =
  /^(http(s)*:\/\/){0,1}(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)|(([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-]+\.[a-zA-Z]{2,63}?)){1}/;

/**
 * Sid Link validation
 */
export const sidLinkValidation = /^(http(s)*:\/\/){0,1}(www\.){0,1}(s\.id)/;
