import clsx from "clsx";

const InfoCard = ({ icon, title, description, image, iconClassName }) => {
  return (
    <div className="bg-white rounded-xl overflow-hidden h-full flex flex-col">
      <div className="p-4 md:p-6 flex-1">
        <div className={clsx("w-5 md:w-7 mb-2", iconClassName)}>{icon}</div>
        <h2 className="font-semibold md:text-xl font-montserrat">{title}</h2>
        <p className="md:mt-2 text-sm md:text-base leading-relaxed">
          {description}
        </p>
      </div>

      <div className="bg-slate-200">
        <img src={image} alt="" />
      </div>
    </div>
  );
};

export default InfoCard;
