import {
  CalendarDaysIcon,
  ChartPieIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { Fragment } from "react";

import { Trans } from "next-i18next";
import InfoCard from "../InfoCard";

const LandingSectionShortener = () => {
  return (
    <Fragment>
      <section className="text-white flex flex-wrap mt-10 md:mt-28 mb-10 md:mb-20 items-center">
        <div className="w-full md:w-6/12 md:pr-10 text-center md:text-left">
          <p className="uppercase font-bold bg-red-500 text-white inline-block px-2 py-0.5 rounded-md mb-4 text-sm">
            <Trans i18nKey="shortener">Shortener</Trans>
          </p>

          <h1 className="text-xl md:text-6xl font-bold font-montserrat leading-tight px-6 md:px-0">
            <Trans i18nKey="landing:section_shortener_heading">
              Simple, fast, and memorizeable
            </Trans>
          </h1>

          <p className="md:text-xl mt-3 leading-relaxed font-worksans px-6 md:px-0">
            <Trans i18nKey="landing:section_shortener_description">
              Experience the pinnacle of convenience with our cutting-edge URL
              shortener service, designed to encapsulate the essence of
              simplicity, speed, and memorability.
            </Trans>
          </p>
          {/* <Button className="text-xl mt-6 font-semibold" roundFull>
          Learn More
        </Button> */}
        </div>
        <div className="w-full md:w-6/12 px-6 md:px-0">
          <div className="w-full bg-slate-100 pb-[75%] rounded-2xl relative scale-90">
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                src={"/images/landing/landing-shorten-0.png"}
                alt=""
                className="shadow-2xl rounded-lg scale-110"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="flex flex-col md:flex-row gap-6 px-6 md:px-0">
        <div className="w-full md:w-4/12">
          <InfoCard
            title={
              <Trans i18nKey="landing:built_in_analytics">
                Built in Analytics
              </Trans>
            }
            description={
              <Trans i18nKey="landing:built_in_analytics_description">
                Track click-through rates, geographic insights, and referral
                sources, all within a single, user-friendly dashboard.
              </Trans>
            }
            icon={<ChartPieIcon />}
            iconClassName="text-red-600"
            image="/images/landing/landing-shorten-1.png"
          />
        </div>
        <div className="w-full md:w-4/12">
          <InfoCard
            title={
              <Trans i18nKey="landing:protected_links">Protected Links</Trans>
            }
            description={
              <Trans i18nKey="landing:protected_links_description">
                Safely share confidential documents, premium resources, and
                sensitive information. Granting access only to authorized users.
              </Trans>
            }
            icon={<LockClosedIcon />}
            iconClassName="text-red-600"
            image="/images/landing/landing-shorten-2.png"
          />
        </div>
        <div className="w-full md:w-4/12">
          <InfoCard
            title={
              <Trans i18nKey="landing:time_based_links">Time Based Links</Trans>
            }
            description={
              <Trans i18nKey="landing:time_based_links_description">
                Make your links timely and relevant, set expiration dates for
                your shared links, adding a sense of urgency and enhancing their
                relevance
              </Trans>
            }
            icon={<CalendarDaysIcon />}
            iconClassName="text-red-600"
            image="/images/landing/landing-shorten-3.png"
          />
        </div>
      </section>
    </Fragment>
  );
};

export default LandingSectionShortener;
