import {
  ClipboardIcon,
  ClockIcon,
  LinkIcon,
  LockOpenIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import Spinner from "@sid/core/components/icon/Spinner";
import { useUserContext } from "@sid/core/context/UserContext";
import { LinkInterface } from "@sid/core/interface/link";
import api, { handleAxioError } from "@sid/core/util/api";
import {
  linkValidation,
  sidLinkValidation,
} from "@sid/core/vars/link-validation";
import axios from "axios";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import { Trans, useTranslation } from "next-i18next";
import { Fragment, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useCopyToClipboard } from "react-use";
import * as Yup from "yup";
import Button from "./Button";
import InputCaptcha from "./InputCaptcha";
import ModalComponent, { useModal } from "./Modal";
import ShareButton from "./ShareButton";

const NewPublicShorten = ({ onFocus }) => {
  const captchaModal = useModal();
  const { authModal } = useUserContext();
  const [, copyText] = useCopyToClipboard();
  const [canCopy, setCanCopy] = useState(true);
  const modal = useModal();
  const { t } = useTranslation();
  const onSubmit = async (values, { setValues, setErrors, setFieldValue }) => {
    if (values.captcha === "") {
      captchaModal.setOpen(true);
      return;
    }
    try {
      // await new Promise((r) => setTimeout(r, 500));
      // const _shortURL = process.env.NEXT_PUBLIC_SHORT_DOMAIN + "/" + "testUrl";
      // setValues(
      //   {
      //     ...values,
      //     captcha: "",
      //     result: _shortURL,
      //     short: "testUrl",
      //     success: true,
      //   },
      //   false
      // );
      // setTimeout(() => setFieldValue("long_url", "", false), 100);
      // modal.setOpen(true);
      // return;

      if (!/^(http|https):\/\//.test(values.long_url)) {
        values.long_url = "https://" + values.long_url;
      }

      const { data } = await api().post<{ data: LinkInterface }>("shorten", {
        long_url: values.long_url,
        captcha: values.captcha,
      });

      const shortURL =
        process.env.NEXT_PUBLIC_SHORT_DOMAIN + "/" + data?.data?.short;

      setValues(
        {
          ...values,
          ...data.data,
          captcha: "",
          result: shortURL,
          success: true,
        },
        false
      );
      setTimeout(() => setFieldValue("long_url", "", false), 100);
      modal.setOpen(true);
    } catch (e) {
      setValues({ ...values, captcha: "" });
      if (axios.isAxiosError(e)) {
        handleAxioError(e, {
          setErrors,
          t,
          toast,
          onError: (msg) => {
            if (msg === "err_cooldown") {
              authModal.open({
                message: t("login_register_to_continue_shorten"),
              });
              return true;
            }
            return false;
          },
        });
      }
    }
  };

  const validateShorten = useMemo(
    () =>
      Yup.object().shape({
        long_url: Yup.string()
          .test("contain-sid", t("cannot_short_sid"), (value) => {
            return !sidLinkValidation.test(value || "");
          })
          .matches(linkValidation, t("invalid_url_format"))
          .required(t("validation_required", { field: t("long_url") })),
      }),
    [t]
  );

  const onCopy = (link) => {
    if (!canCopy) return;
    setCanCopy(false);
    copyText(link);
    setTimeout(() => {
      setCanCopy(true);
    }, 2000);
  };

  return (
    <Formik
      initialValues={{
        long_url: "",
        short: "",
        result: "",
        captcha: "",
        success: false,
      }}
      validationSchema={validateShorten}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, values, isSubmitting, errors, touched }) => (
        <Form className="mx-6">
          <div className="mx-auto max-w-[590px] bg-white text-lg md:text-2xl font-montserrat font-semibold p-1 rounded-md flex items-center">
            <Field
              type="text"
              name="long_url"
              className={clsx("w-full mx-2", {
                "opacity-50": isSubmitting,
              })}
              onFocus={onFocus}
              disable={isSubmitting}
              placeholder={t("short_url_placeholder")}
            />
            <Button
              color="primary"
              className="text-white font-semibold flex items-center !p-1 px-2 md:!px-3 md:!py-2"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner className="animate-spin w-4 md:w-6 mr-2" />
              ) : (
                <LinkIcon className="w-4 md:w-6 mr-2" />
              )}
              <Trans i18nKey="landing:shorten_feat.button" />
            </Button>
          </div>
          {/* <ErrorMessage name="long_url"> */}
          {/* {(msg) => ( */}
          {errors.long_url && touched.long_url ? (
            <div className="max-w-[590px] mx-auto relative">
              <div className="bg-red-500 text-white absolute top-1 rounded-md px-4 py-2 w-full">
                {errors.long_url as any}
              </div>
            </div>
          ) : (
            <Fragment />
          )}
          {/* // )} */}
          {/* </ErrorMessage> */}
          <ModalComponent {...modal} disabled>
            <h1 className="text-lg md:text-2xl font-montserrat font-bold md:text-center mb-6">
              ✨ Your Link si Ready!
            </h1>

            <button
              className="p-2 absolute top-2.5 right-2.5"
              onClick={modal.close}
            >
              <XMarkIcon className="w-6 md:w-7" />
            </button>

            <div className="text-xl bg-white py-2 pl-3 pr-2 border border-gray-200 flex items-center rounded-md shadow-lg">
              <div className="flex-1 truncate font-montserrat font-semibold">
                <span className="text-red-500">s</span>.id/
                <span className="underline">{values?.short}</span>{" "}
                <Button color="secondary" size="sm" disabled>
                  <PencilSquareIcon className="w-4 -mx-0.5" />
                </Button>
              </div>
              <div className="text-base">
                <Button
                  color="secondary"
                  className="flex items-center -m-1 !py-1"
                  onClick={() => onCopy(values.result)}
                >
                  <span className="mr-2">{canCopy ? "Copy" : "Copied"}</span>
                  <ClipboardIcon className="w-5 -mr-1" />
                </Button>
              </div>
            </div>
            <div className="flex justify-end mt-2 gap-2">
              <Button
                color="secondary"
                size="sm"
                className="flex items-center"
                disabled
              >
                <ClockIcon className="w-4" />
              </Button>

              <Button
                color="secondary"
                size="sm"
                className="flex items-center"
                disabled
              >
                <LockOpenIcon className="w-4" />
              </Button>

              <ShareButton url={values.result} withCopy />
            </div>

            <div className="text-sm md:text-base text-center mt-4">
              💎 <Trans i18nKey="login_to_use_all_feature" />
            </div>
          </ModalComponent>
          <ModalComponent {...captchaModal}>
            <div className="flex">
              <h1 className="text-2xl flex-1 font-montserrat font-semibold">
                🤖 {t("solve_captcha")}
              </h1>
              <button onClick={() => captchaModal.setOpen(false)}>
                <XMarkIcon className="w-6" />
              </button>
            </div>
            <p className="my-1 font-worksans">{t("please_solve_captcha")}</p>
            <div className="flex justify-center my-10">
              <InputCaptcha
                action="report"
                onSuccess={() => {
                  captchaModal.setOpen(false);
                  setTimeout(() => {
                    handleSubmit();
                  }, 500);
                }}
              />
            </div>
          </ModalComponent>
        </Form>
      )}
    </Formik>
  );
};

export default NewPublicShorten;
